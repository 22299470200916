import gql from 'graphql-tag';

export const productInfoFragment = gql`
  fragment productInfo on Product {
    id
    billing {
      configurations {
        name
      }
    }
    production {
      settings
      envs {
        name
        data
      }
      sensors {
        type
        name
        datapoints {
          name
          active
          description
          type
          unit
          interval
          maxInterval
        }
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query product($id: ID!) {
    product(id: $id) {
      ...productInfo
    }
  }
  ${productInfoFragment}
`;
