export function exportDevicesIdsToCsv(filename, ids) {
  let csv = '';

  csv += 'id,\n';

  ids.forEach((id) => {
    csv += id.concat(',\n');
  });

  //Adding csv header
  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  let link = document.createElement('a');
  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  document.body.append(link);
  link.click();
  document.body.removeChild(link);
}
