import React from 'react';
import Flasher from './Flasher';
import { useQuery } from '@apollo/react-hooks';
import { GET_DEVICES } from '../api/queries/devices';
import styled from 'styled-components';
import Card from '@components/atoms/Card';
import Caption from '@components/atoms/Caption';
import LoaderLine from '@components/atoms/LoaderLine';
import PropTypes from 'prop-types';
import { Flex, Box } from '@rebass/grid';

const StyledCaption = styled(Caption)`
  padding: 0.2em 0.8em 0.5em 0;
`;

const InfoCard = styled(Card)`
  border-left: ${(props) => props.theme.cardLeftBorder};
  padding: 2em;
`;

const Flashers = ({ tag, toaster }) => {
  const title = 'Flashers';
  let { data, error, loading } = useQuery(GET_DEVICES, {
    variables: {
      filter: {
        tagExact: tag,
        product: 'ble-flasher',
      },
      limit: 0,
    },
  });
  if (error)
    toaster.addToast({
      type: 'error',
      title: 'Error',
      message: error.message || error,
    });

  return (
    <InfoCard title={title}>
      <StyledCaption>{title}</StyledCaption>
      <Flex flexWrap={['wrap', null]}>
        <LoaderLine loading={loading} />
        {data &&
          data.devices &&
          data.devices.edges.map((device, index) => (
            <Box
              width={[1, 1, 1 / 3]}
              padding={'0.5em'}
              key={`configurator-${index}`}
            >
              <Flasher device={device} />
            </Box>
          ))}
      </Flex>
    </InfoCard>
  );
};

Flashers.propTypes = {
  tag: PropTypes.string,
  toaster: PropTypes.object,
};

export default Flashers;
