import React, { useState, useEffect, useCallback } from 'react';
import useRealtimeDevice from '../../hooks/useRealtimeDevice';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import Button from '../../atoms/Button';
import dayjs from 'dayjs';
import CaptionCard from '../../molecules/CaptionCard';
import SkeletonLoadingCard from '../SkeletonLoadingCard';
import TableRow from '../../atoms/TableRow';
import TableCell from '../../atoms/TableCell';
import TableHead from '../../atoms/TableHead';
import Table from '../../molecules/Table';

import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  START_DEVICE_TESTS,
  CHANGE_TEST_STATE,
} from '../../api/mutations/device-tests';
import { GET_DEVICE_TESTS } from '../../api/queries/device-tests';

const timeout = 24 * 60 * 60;

const StyledBox = styled((props) => <Box {...props} />)`
  ${(props) =>
    props.zoomed &&
    `
    width:100%;
    height:100%;
  `} ${(props) =>
    props.hidden &&
    `
    display:none;
  `};
`;

const StyledCard = styled((props) => <CaptionCard {...props} />)`
  position: relative;
  ${(props) => (props.zoomed ? `height: auto` : `height: 400px;`)}
`;

const StyledTableHead = styled(TableHead)`
  padding-top: 0;
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;

const TestDetails = styled.p`
  white-space: pre;
  overflow-x: auto;
  max-width: 20em;
`;
const DeviceTestsCard = (props) => {
  const { id, toaster, onZoom, zoomed, hidden, device } = props;
  const title = 'Device Tests';
  const { addToast } = toaster;
  const {
    data,
    error,
    loading,
    startPolling,
    stopPolling,
    refetch,
  } = useQuery(GET_DEVICE_TESTS, { variables: { deviceId: id } });
  const {
    loading: loadingRealtimeDevice,
    connected: realtimeDeviceConnected,
    device: connectedDevice,
  } = useRealtimeDevice(device);

  if (error) {
    addToast({
      type: 'error',
      title: `Error`,
      message: `Server error ${error.message || error}`,
      timeout,
    });
  }

  zoomed ? startPolling(5000) : stopPolling();

  const [startDeviceTests] = useMutation(START_DEVICE_TESTS);
  const [changeState] = useMutation(CHANGE_TEST_STATE);
  const [deviceTests, setDeviceTests] = useState();

  useEffect(() => {
    setDeviceTests(data && data.deviceProductionTests);
  }, [data]);

  const states = {
    ONGOING: 'ongoing',
    COMPLETED: 'completed',
    FAILED: 'failed',
    WAITING: 'waiting',
  };
  const types = {
    MANUAL: 'manual',
    AUTO: 'auto',
  };

  const startTests = useCallback(() => {
    startDeviceTests({
      variables: {
        id,
      },
    })
      .then(({ data }) => {
        addToast({
          type: 'success',
          title: `Success`,
          message: `Device tests have been started`,
        });
        setDeviceTests(data.startDeviceTests);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: `Error`,
          message: `Start tests request failed ${error.message}`,
          timeout,
        });
      });
  });

  const changeTestState = useCallback((name, state) => {
    changeState({
      variables: {
        deviceId: id,
        input: {
          name,
          state,
        },
      },
    })
      .then(({ data }) => {
        addToast({
          type: 'success',
          title: `Success`,
          message: `'${name}' test state updated to '${state}'`,
        });
        setDeviceTests(data.changeTestState);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: `Error`,
          message: `Start tests request failed ${error.message}`,
          timeout,
        });
      });
  });

  const sendCustomCommand = useCallback(
    async (command, name, testName) => {
      try {
        await connectedDevice.sendCustom(command, { name: testName });
        refetch();
        addToast({
          type: 'success',
          title: `Success`,
          message: `'${name}' command has been sent to device!'`,
        });
      } catch (err) {
        console.log(err);
        addToast({
          type: 'error',
          title: `Error`,
          message: `Error sending '${name}' command to device`,
          timeout,
        });
      }
    },
    [connectedDevice]
  );

  if (loading) return <SkeletonLoadingCard title={title} {...props} />;

  return (
    <StyledBox
      hidden={hidden}
      p={'.2em'}
      flex="1 1 auto"
      width={[1, null, 1 / 2]}
    >
      <StyledCard
        zoomed={zoomed}
        title={title}
        style={{ position: 'relative' }}
      >
        {!deviceTests && (
          <Flex
            flexDirection="column"
            alignItems="center"
            style={{ height: '85%' }}
          >
            <Box style={{ overflow: 'hidden', color: 'black' }}>
              Tests haven't been run...
            </Box>
            <Box>
              <Button secondary small onClick={startTests}>
                Start Tests
              </Button>
            </Box>
          </Flex>
        )}
        <StyledFlex flexDirection="column" alignItems="flex-start">
          {deviceTests && (
            <Box width={1} flex="1" style={{ overflow: 'hidden' }}>
              <Table>
                <TableRow>
                  <StyledTableHead>State</StyledTableHead>
                  <StyledTableHead>Start</StyledTableHead>
                  <StyledTableHead>End</StyledTableHead>
                  <StyledTableHead>Tests</StyledTableHead>
                </TableRow>
                <TableRow key="production-test-data-row">
                  <TableCell>{deviceTests.state}</TableCell>
                  <TableCell>
                    {' '}
                    {deviceTests.start
                      ? dayjs(deviceTests.start).format('DD.MM.YYYY H:mm:ss')
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {' '}
                    {deviceTests.end
                      ? dayjs(deviceTests.end).format('DD.MM.YYYY H:mm:ss')
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {deviceTests.tests.map(({ name }) => name).join(',')}
                  </TableCell>
                  <TableCell>
                    <Button small onClick={startTests}>
                      Start Tests
                    </Button>
                  </TableCell>
                </TableRow>
              </Table>
              {zoomed && (
                <Table caption="Tests Info">
                  <TableRow>
                    <StyledTableHead>Name</StyledTableHead>
                    <StyledTableHead>State</StyledTableHead>
                    <StyledTableHead>Type</StyledTableHead>
                    <StyledTableHead>Start</StyledTableHead>
                    <StyledTableHead>End</StyledTableHead>
                    <StyledTableHead>Details</StyledTableHead>
                    <StyledTableHead>Actions</StyledTableHead>
                  </TableRow>
                  {deviceTests.tests.map((test, i) => {
                    return (
                      <TableRow key={`production-tests-test-details-${i}`}>
                        <TableCell>{test.name}</TableCell>
                        <TableCell>{test.state}</TableCell>
                        <TableCell>{test.type}</TableCell>
                        <TableCell>
                          {test.details.start
                            ? dayjs(test.details.start).format(
                                'DD.MM.YYYY H:mm:ss'
                              )
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {test.details.end
                            ? dayjs(test.details.end).format(
                                'DD.MM.YYYY H:mm:ss'
                              )
                            : '-'}
                        </TableCell>
                        <TableCell>
                          <TestDetails>
                            {JSON.stringify(test.details.data, null, 1)}
                          </TestDetails>
                        </TableCell>
                        <TableCell>
                          {test.state === states.ONGOING &&
                            test.type === types.MANUAL && (
                              <Flex flexWrap="wrap" justifyContent="center">
                                <Box>
                                  <Button
                                    small
                                    secondary
                                    onClick={() =>
                                      changeTestState(
                                        test.name,
                                        states.COMPLETED
                                      )
                                    }
                                  >
                                    Completed
                                  </Button>
                                </Box>
                                <Box>
                                  <Button
                                    small
                                    secondary
                                    onClick={() =>
                                      changeTestState(test.name, states.FAILED)
                                    }
                                  >
                                    Failed
                                  </Button>
                                </Box>
                              </Flex>
                            )}
                          {((test.state === states.WAITING &&
                            test.type === types.MANUAL) ||
                            ![states.ONGOING].includes(test.state)) && (
                            <Button
                              small
                              secondary
                              onClick={() =>
                                changeTestState(test.name, states.ONGOING)
                              }
                            >
                              Start
                            </Button>
                          )}
                          {![states.COMPLETED].includes(test.state) && (
                            <Button
                              style={{ marginLeft: 10 }}
                              small
                              secondary
                              onClick={() =>
                                changeTestState(test.name, states.COMPLETED)
                              }
                            >
                              Skip test
                            </Button>
                          )}
                        </TableCell>
                        <TableCell>
                          {test.deviceCommands &&
                            test.state === states.ONGOING &&
                            test.deviceCommands.map(({ name, command }, i) => (
                              <Box
                                key={`deviceProductionTests-${i}`}
                                flex="1 1 auto"
                              >
                                <Button
                                  small
                                  onClick={() =>
                                    sendCustomCommand(command, name, test.name)
                                  }
                                >
                                  {name}
                                </Button>
                              </Box>
                            ))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Table>
              )}
            </Box>
          )}
          <Flex width={'100%'} justifyContent="flex-end">
            {!zoomed && (
              <Button onClick={onZoom} secondary>
                More...
              </Button>
            )}
          </Flex>
        </StyledFlex>
      </StyledCard>
    </StyledBox>
  );
};

DeviceTestsCard.propTypes = {};

export default DeviceTestsCard;
