import gql from 'graphql-tag';
import { eventInfoFragment, eventDataFragment } from '../fragments/events';
import { pageInfoFragment } from '../fragments/pageInfo';

export const GET_EVENTS = gql`
  query events($after: String, $limit: Int, $filter: EventFilter, $sort: JSON) {
    events(after: $after, limit: $limit, filter: $filter, sort: $sort) {
      edges {
        ...eventInfo
        ...eventData
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${eventInfoFragment}
  ${eventDataFragment}
  ${pageInfoFragment}
`;
