import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';

import theme, { GlobalStyle } from '@components/themes/dashboard';
import Cookies from 'universal-cookie';

import { FirebaseProvider, AuthProvider } from '@contexts/FirebaseContext';

import { SharedToasterProvider } from '@contexts/ToasterContext';
// import ToasterContext from '@components/contexts/ToasterContext';
import { QSProvider } from '@components/contexts/QSContext';

import {
  apolloClient,
  /*  setTag,
  getTag,*/
} from '@components/connect-react-lib/server-api';

import { LivionConnectProvider } from '@components/contexts/LivionConnectContext';
import { logout } from '@components/connect-react-lib';

import App from './App';
// TO deploy
const cookies = new Cookies();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
};

localStorage.setItem('x-app', 'production');

const navigationItems = [
  { to: '/devices', label: 'Devices' },
  { to: '/create', label: 'Create' },
  {
    onClick: () => {
      window.location = `/apps/en/users`;
    },
    label: 'Users',
  },
  {
    onClick: () => {
      window.localStorage.removeItem('selectedOrganizationUnit');
      cookies.remove('x-tag', {
        path: '/',
      });
      window.location = `/apps/`;
    },
    label: 'Change organization',
  },
  {
    onClick: () => {
      logout();
    },
    label: 'Logout',
  },
];

ReactDOM.render(
  <FirebaseProvider {...firebaseConfig}>
    <AuthProvider tagSelect={false} productFilter="production-admin">
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <LivionConnectProvider navigationItems={navigationItems}>
            <ThemeProvider theme={theme}>
              <SharedToasterProvider>
                <QSProvider>
                  <App />
                  <GlobalStyle />
                </QSProvider>
              </SharedToasterProvider>
            </ThemeProvider>
          </LivionConnectProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </AuthProvider>
  </FirebaseProvider>,
  document.getElementById('root')
);

// registerServiceWorker();
