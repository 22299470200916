import React, { useState, useEffect, useContext, useCallback } from 'react';
import ToasterContext from '@components/contexts/ToasterContext';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PRODUCT } from '../api/queries/products';
import _get from 'lodash.get';
import DashboardFlexibleFormPage from '@components/pages/DashboardFlexibleFormPage';
import TextField from '@components/fields/TextField';
import NumberField from '@components/fields/NumberField';
import CheckboxField from '@components/fields/CheckboxField';
import { required, clean } from '@components/utils';
import { Box, Flex } from '@rebass/grid';
import Fieldset from '@components/atoms/Fieldset';
import TagSelectSearch from '@components/molecules/TagSelectSearch';
import EditorField from '@components/fields/EditorField';
import SelectProduct from '../Devices/SelectProduct';
import SelectField from '@components/fields/SelectField';
import SensorsForm from '@components/organisms/SensorsForm';
import { CREATE_DEVICES } from '../api/mutations/devices';
import { exportDevicesIdsToCsv } from '../utils';
const DevicesCreate = (props) => {
  const { auth, history } = props;
  const { addToast } = useContext(ToasterContext);

  let [tag] = useState(auth.getSelectedTag());
  let [product, setProduct] = useState(
    JSON.parse(window.localStorage.getItem('product'))
  );

  const { data, loading: queryLoading, error } = useQuery(GET_PRODUCT, {
    variables: { id: product.name },
    skip: !product,
  });

  const [loading, setLoading] = useState(queryLoading);

  useEffect(() => {
    setLoading(queryLoading);
  }, [queryLoading]);

  const [createDevices] = useMutation(CREATE_DEVICES);

  useEffect(() => {
    if (error)
      addToast({
        type: 'error',
        title: `Error`,
        message: error.message || error,
      });
  }, [error, addToast]);

  const onSubmit = useCallback(
    (data) => {
      const input = {
        name: data.name,
        tag: data.tag,
        configuration: data.configuration,
        product: data.product,
        env: data.env,
        settings: {
          data: data.settings ? JSON.parse(data.settings) : {},
        },
        sensors:
          data.sensors &&
          clean(
            data.sensors.map((sensor) => {
              if (sensor.datapoints) {
                sensor.datapoints = clean(sensor.datapoints);
              }
              return sensor;
            })
          ),
      };

      setLoading(true);
      createDevices({
        variables: {
          input,
          quantity: data.quantity,
          generateHwId: data.generateHwId,
        },
      })
        .then(({ data }) => {
          addToast({
            type: 'success',
            title: `Success`,
            message: `Devices created successfully`,
          });
          const ids = data.createDevicesFromProduction;

          setLoading(false);
          exportDevicesIdsToCsv(
            `${product.name}_devices_${Date.now()}.csv`,
            ids
          );
        })
        .catch((error) => {
          setLoading(false);
          addToast({
            type: 'error',
            title: `Error`,
            message: `Error: ${error.message}`,
          });
        });
    },
    [addToast, createDevices, product.name]
  );

  return (
    <DashboardFlexibleFormPage
      initialValues={{
        ..._get(data, 'product.production'),
        product: product.name,
        tag,
      }}
      onSubmit={onSubmit}
      title={product.name}
      onBack={() => history.push(`/devices`)}
      loading={loading}
      mutators={{
        environmentChange: ([value], state, { changeValue }) =>
          changeValue(state, 'env', () => value),
      }}
    >
      {({ form }) => {
        return (
          <>
            <Fieldset label="Product" borderless={true}>
              <SelectProduct
                onChange={(product) => setProduct(product)}
                product={product}
              />
            </Fieldset>
            <Fieldset label="Main Info">
              <Flex flexWrap="wrap">
                <Box width={[1, 1, 1]}>
                  <CheckboxField
                    name={'generateHwId'}
                    label="Generate Hardware ID"
                    wrapperStyles={{ paddingRight: '10px' }}
                  />
                </Box>
                <Box width={[1, 1, 1 / 2]}>
                  <NumberField
                    name={'quantity'}
                    label="Quantity"
                    validate={required}
                    wrapperStyles={{ paddingRight: '10px' }}
                  />
                </Box>
                <Box width={[1, 1, 1 / 2]}>
                  <TextField
                    name="name"
                    validate={required}
                    label="Name"
                    wrapperStyles={{ paddingRight: '10px' }}
                  />
                </Box>
                <Box width={[1, 1, 1 / 2]}>
                  <SelectField
                    name="variables"
                    label="Environment"
                    labelKey="name"
                    valueKey="data"
                    onChange={form.mutators.environmentChange}
                    options={_get(data, 'product.production.envs', [])}
                    wrapperStyles={{ paddingRight: '10px' }}
                  />
                </Box>
                <Box width={[1, 1, 1 / 2]}>
                  <SelectField
                    name="configuration"
                    label="Configuration"
                    labelKey="name"
                    valueKey="name"
                    options={_get(data, 'product.billing.configurations', [])}
                    wrapperStyles={{ paddingRight: '10px' }}
                  />
                </Box>
                <Box width={[1, 1, 1]}>
                  <TagSelectSearch
                    validate={required}
                    name="tag"
                    label="Tag"
                    wrapperStyles={{ paddingRight: '10px' }}
                  />
                </Box>
              </Flex>
            </Fieldset>
            <Fieldset label="Environment variables">
              <Box width={[1, 1, 1]}>
                <EditorField key="env" name="env" />
              </Box>
            </Fieldset>
            <SensorsForm name="sensors" label="Sensors" />
            <Fieldset label="Settings">
              <Box width={[1, 1, 1]}>
                <EditorField key="settings" name="settings" />
              </Box>
            </Fieldset>
          </>
        );
      }}
    </DashboardFlexibleFormPage>
  );
};

export default DevicesCreate;
