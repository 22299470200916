import React from 'react';
import TextField from '@components/fields/TextField';
import Fieldset from '@components/atoms/Fieldset';
import NumberField from '@components/fields/NumberField';
import SelectField from '@components/fields/SelectField';
import { Flex, Box } from '@rebass/grid';
import ArrayField from '@components/fields/ArrayField';
import Accordion from '@components/molecules/Accordion';
import { Field } from 'react-final-form';

const SensorsForm = ({ name, label }) => {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <Fieldset label={label}>
          <ArrayField name={name}>
            {({ fields }) => {
              return fields.map((name, index) => (
                <Accordion
                  key={name}
                  remove={() => fields.remove(index)}
                  title={
                    (input.value &&
                      input.value[index] &&
                      input.value[index].name) ||
                    ''
                  }
                  open={false}
                >
                  {({ changeTitle }) => (
                    <Flex flexWrap="wrap">
                      <Box width={[1, 1, 1 / 2]}>
                        <TextField
                          key="name"
                          name={`${name}.name`}
                          onChange={(event) => {
                            changeTitle(event.target.value);
                          }}
                          label="Name"
                          wrapperStyles={{
                            paddingRight: '10px',
                          }}
                        />
                      </Box>
                      <Box width={[1, 1, 1 / 2]}>
                        <TextField
                          key="type"
                          name={`${name}.type`}
                          label="Type"
                          wrapperStyles={{
                            paddingRight: '10px',
                          }}
                        />
                      </Box>
                      <Box width={[1, 1, 1]}>
                        <Field name={`${name}.datapoints`}>
                          {({ input, meta }) => (
                            <Fieldset label="Datapoints">
                              <ArrayField name={`${name}.datapoints`}>
                                {({ fields }) => {
                                  return fields.map((datapoint, index) => (
                                    <Accordion
                                      key={datapoint}
                                      remove={() => fields.remove(index)}
                                      title={
                                        (input.value &&
                                          input.value[index] &&
                                          input.value[index].name) ||
                                        ''
                                      }
                                      open={false}
                                    >
                                      {({ changeTitle }) => (
                                        <Flex flexWrap="wrap">
                                          <Box width={[1, 1, 1 / 2]}>
                                            <TextField
                                              key="name"
                                              name={`${datapoint}.name`}
                                              onChange={(event) => {
                                                changeTitle(event.target.value);
                                              }}
                                              label="Name"
                                              wrapperStyles={{
                                                paddingRight: '10px',
                                              }}
                                            />
                                          </Box>
                                          <Box width={[1, 1, 1 / 2]}>
                                            <SelectField
                                              name={`${datapoint}.active`}
                                              label="active"
                                              labelKey="active"
                                              options={[
                                                {
                                                  active: 'Active',
                                                  value: true,
                                                },
                                                {
                                                  active: 'Passive',
                                                  value: false,
                                                },
                                              ]}
                                              wrapperStyles={{
                                                paddingRight: '10px',
                                              }}
                                            />
                                          </Box>
                                          <Box width={[1, 1, 1 / 2]}>
                                            <TextField
                                              key="description"
                                              name={`${datapoint}.description`}
                                              label="Description"
                                              wrapperStyles={{
                                                paddingRight: '10px',
                                              }}
                                            />
                                          </Box>
                                          <Box width={[1, 1, 1 / 2]}>
                                            <TextField
                                              name={`${datapoint}.type`}
                                              label="type"
                                              wrapperStyles={{
                                                paddingRight: '10px',
                                              }}
                                            />
                                          </Box>
                                          <Box width={[1, 1, 1 / 2]}>
                                            <TextField
                                              name={`${datapoint}.unit`}
                                              label="unit"
                                              wrapperStyles={{
                                                paddingRight: '10px',
                                              }}
                                            />
                                          </Box>
                                          <Box width={[1, 1, 1 / 2]}>
                                            <NumberField
                                              name={`${datapoint}.interval`}
                                              label="interval"
                                              optional={true}
                                              wrapperStyles={{
                                                paddingRight: '10px',
                                              }}
                                            />
                                          </Box>
                                          <Box width={[1, 1, 1 / 2]}>
                                            <NumberField
                                              name={`${datapoint}.minInterval`}
                                              label="minInterval"
                                              optional={true}
                                              wrapperStyles={{
                                                paddingRight: '10px',
                                              }}
                                            />
                                          </Box>
                                          <Box width={[1, 1, 1 / 2]}>
                                            <NumberField
                                              name={`${datapoint}.maxInterval`}
                                              label="maxInterval"
                                              optional={true}
                                              wrapperStyles={{
                                                paddingRight: '10px',
                                              }}
                                            />
                                          </Box>
                                        </Flex>
                                      )}
                                    </Accordion>
                                  ));
                                }}
                              </ArrayField>
                            </Fieldset>
                          )}
                        </Field>
                      </Box>
                    </Flex>
                  )}
                </Accordion>
              ));
            }}
          </ArrayField>
        </Fieldset>
      )}
    </Field>
  );
};

export default SensorsForm;
