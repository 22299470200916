import { createGlobalStyle } from 'styled-components';
import c from 'color';
import './Cabin-Regular.ttf';
import './Cabin-Medium.ttf';
import './Cabin-SemiBold.ttf';
import Logo from './Logo';

export const primaryThemeColor = '#00C1BA';
const secondaryThemeColor = '#5B5B5B';
const tertiaryThemeColor = '#00C1BA';
const background = '#fff';
const errorColor = '#C54747';
const alertColor = '#EF6868';
const disconnectedColor = '#5B5B5B';

export const GlobalStyle = createGlobalStyle`
  body {
    font-size: 14px;
    font-family: Cabin, sans-serif;
    background: ${background};
    color: ${primaryThemeColor}
  }

`;

export const theme = {
  Logo,
  //breakpoints: ["40em", "52em", "64em"],
  name: 'Dashboard Theme',
  /**
   * Global stylenames/styles, used in various atoms
   */
  primaryFont: 'Cabin, sans-serif',
  primaryThemeColor,
  secondaryThemeColor,
  tertiaryThemeColor,
  errorColor,
  alertColor,
  background,

  /**
   * Block styles
   */
  blockBackground: 'none',
  blockColor: '#717171',
  blockPadding: '0 0 0 0',

  /**
   * Paragraph styles
   */
  paragraphColor: secondaryThemeColor,
  paragraphFontWeight: '400',
  paragraphLineHeight: 'normal',
  paragraphFontSize: '1.1em',
  paragraphTextTransform: 'none',
  paragraphTextAlign: 'justify',
  paragraphPadding: '1.5em 0.8em 0.5em 0.8em',
  paragraphMargin: '0',

  /**
   * Li styles
   */
  liColor: secondaryThemeColor,
  liFontWeight: '400',
  liLineHeight: 'normal',
  liFontSize: '1.1em',
  liTextTransform: 'none',
  liTextAlign: 'justify',
  liPadding: '0.5em 0.8em 0.5em 0.8em',
  liMargin: '0',

  /**
   * Caption styles
   */
  captionColor: primaryThemeColor,
  captionFontWeight: '700',
  captionLineHeight: 'normal',
  captionFontSize: '1.55em',
  captionTextTransform: 'none',
  captionTextAlign: 'left',
  captionPadding: '1.5em 0.8em 0.5em 0.8em',
  captionMargin: '0',

  /**
   * CircleButton styles
   */
  circleButtonBackground: '#00C1BA',
  circleButtonDisabledBackground: '#9A9A9A',
  circleButtonDisabledColor: '#6A6A6A',
  circleButtonTextColor: '#fff',
  circleButtonActiveBackground: '#688C8B',
  circleButtonFontSize: '1.35em',
  circleLetterSpacing: 'normal',

  /**
   * Heading styles
   */
  headingFontWeight: '500',
  headingColor: '#717171',
  headingPadding: '0.2em 0.2em 0.2em 0em',

  /**
   * HorizontalRule styles
   */
  horizontalRuleColor: '#DBDBDB',
  horizontalRuleBorder: '0 0 2',
  horizontalRuleMargin: '0',

  /**
   * Link styles
   */
  linkTextDecoration: 'underline',
  linkFontWeight: '500',
  linkTransform: 'none',
  linkColor: primaryThemeColor,

  /**
   * ListItem styles
   */
  listItemColor: primaryThemeColor,
  listItemPadding: '1em 0 0 0',
  listItemStyleType: 'none',

  /**
   * Loader styles
   */
  loaderBackground: primaryThemeColor,

  /**
   * PrimaryButton styles
   */
  primaryBackground: primaryThemeColor,
  primaryButtonTextColor: '#fff',
  primaryButtonBorder: 'none',
  primaryBorderRadius: '50px',
  primaryButtonMargin: '0.5em 0',
  primaryButtonTextPadding: '0.48em 1.8em',
  primaryButtonTextTransform: 'none',
  primaryHoverBackground: '#00C1BA',
  primaryActiveBackground: '#688C8B',
  primaryDisabledBackground: '#9A9A9A',
  primaryDisabledColor: '#6A6A6A',
  primaryButtonFontSize: '1.2em',
  primaryButtonMinWidth: '0',
  primaryButtonMinHeight: '0',
  primaryButtonFontWeight: '400',
  primaryLetterSpacing: '0.045em',
  primaryButtonTextAlign: 'left',

  /**
   * SecondaryButton styles
   */
  secondaryBackground: secondaryThemeColor,
  secondaryColor: '#fff',
  secondaryButtonMargin: '0.5em 0',
  secondaryBorderRadius: '50em',
  secondaryButtonBorder: 'none',
  secondaryButtonTextPadding: '0.48em 1.8em',
  secondaryButtonTextTransform: 'none',
  secondaryDisabledBackground: '#9A9A9A',
  secondaryHoverBackground: '#00C1BA',
  secondaryActiveBackground: '#688C8B',
  secondaryDisabledColor: '#6A6A6A',
  secondaryButtonFontSize: '1.2em',
  secondaryButtonMinWidth: '43px',
  secondaryButtonMinHeight: '1.5em',
  secondaryButtonFontWeight: '400',
  secondaryLetterSpacing: '0.045em',
  secondaryButtonTextAlign: 'center',

  /**
   * Table components start-->
   */

  //TableHead
  tableHeadBackground: 'none',
  tableHeadColor: '#717171',
  tableHeadFontWeight: '500',
  tableHeadLineHeight: 'normal',
  tableHeadFontSize: '0.9em',
  tableHeadTextTransform: 'none',
  tableHeadTextAlign: 'left',
  tableHeadPadding: '1.5em 0.5em 0.25em 0.5em',
  tableHeadMargin: '0',
  tableHeadTopBorder: 'none 0',

  //TableCell
  tableCellColor: '#959595',
  tableCellFontWeight: '400',
  tableCellLineHeight: 'normal',
  tableCellFontSize: '0.80em',
  tableCellTextTransform: 'none',
  tableCellTextAlign: 'left',
  tableCellPadding: '0.5em 0.5em 0.5em 0.5em',
  tableCellMargin: '0',
  tableCellTopBorder: 'none 0',

  //TableRow
  tableRowPadding: '0',
  tableRowlMargin: '0',
  tableRowBackground: 'none',
  tableFirstRowBg: '#fafafa',
  tableSecondRowBg: '#fff',

  /**
   * Input(field) styles
   */
  inputPlaceholderColor: '#c7c7c7',
  inputPlaceholderWeight: '400',
  inputFontWeight: '400',
  inputHeight: '2.75em',
  inputFontSize: '1em',
  inputFontColor: '#717171',
  inputBackground: '#fff',
  inputBorderRadius: '1.250em',
  inputBorderFocus: '#43BDFC',
  inputBorder: '#C1C1C1',
  inputBorderError: '#C54747',
  inputPadding: '0 1.25em',
  inputBorderWidth: '1px',
  inputLabelColor: '#989898',
  inputIconFill: '#c7c7c7',
  /**
   * Field error
   */
  fieldError: '#C54747',

  /**
   *  <-- Table components end
   */

  /**
   * Label styles
   */
  labelColor: primaryThemeColor,
  labelTransform: 'uppercase',
  labelBoldColor: tertiaryThemeColor,

  /**
   * Navigation syles
   */
  navigationFontWeigt: '700',
  navigationPadding: '0',
  navigationFontSize: '1.05em',
  navigationFontColor: '#5b5b5b',
  navigationActiveColor: '#00C1BA',

  /**
   * DropDown (menu) styles
   */
  dropdownBackground: '#ffffff',
  dropdownTextColor: '#595959',
  dropdownIconColor: '#595959',
  dropdownShadow: '0 1px 1px 0 rgba(0,0,0,0.17)',
  dropdownBorderRadius: '0 0 5px 5px',
  dropdownPadding: '0.75em',
  dropdownWidth: '200px',
  dropdownItemPadding: '0.5em',
  dropdownMenuHover: '#f8f8f8',

  /**
   * Card styles
   */
  cardBackground: '#fff',
  cardBoxShadow: '0 0.1em 0.2em 0 rgba(0,0,0,0.2)',
  cardBoxShadowHover: '0 0.2em 0.4em 0 rgba(0,0,0,0.2)',
  cardPadding: '0',
  cardColor: primaryThemeColor,

  /**
   * Header styles
   */
  headerBackground: background,

  /**
   * Logo styles
   */
  logoFill: primaryThemeColor,

  /**
   * StatusLabel Styles
   */
  statusLabelSize: '0.7em',
  statusLabelConnectedColor: tertiaryThemeColor,
  statusLabelDisconnectedColor: '#EF6868',
  disconnectedColor: '#EF6868',

  plugColor: '#7D7777',

  /**
   * Panel styles
   */
  subtitleColor: '#9d9d9d',
  panelBackground: '#d8d8d8',

  /**
   * Mobile menu
   */
  mobileMenuIconColor: '#595959',
  drawerBackground: '#fff',
  drawerProfileColor: '#5B5B5B',
  drawerItemColor: '#5B5B5B',
  drawerItemBackgroundColor: '#fff',
  drawerItemActiveColor: '#fff',
  drawerItemActiveBackground: primaryThemeColor,

  /**
   * Toast styles
   */
  toastBackground: '#ffffff',
  toastBorder: '1px solid #E7E7E7',
  toastPadding: '0.5em 0.5em 1em 1em',
  toastShadow: '0px 14px 27px -16px rgba(0,0,0,0.75);',
  toastSuccessColor: '#71E190',
  toastErrorColor: '#E16767',
  toastWarningColor: '#FEFF00',
  toastTitleColor: '#5B5B5B',
  toastMessageColor: '#5B5B5B',

  /**
   * Tree styles
   */
  treeItemBackground: 'none',
  treeItemBackgroundSelected: secondaryThemeColor,
  nodeFontSize: '0.80rem',
  toggleArrowSize: '0.65rem',
  treeTitleColor: primaryThemeColor,
  treeSubtitleColor: '#959595',
  actionIconColor: '#5B5B5B',
  toggleColor: primaryThemeColor,

  /**
   * ToggleButton styles
   */

  toggleButtonSelected: '#cccccc',
  toggleButtonNotSelected: '#fff',
  toggleButtonTextColorSelected: primaryThemeColor,
  toggleButtonTextColorNotSelected: '#cccccc',

  /**
   * Select styles
   */
  selectPadding: '0 .5em',
  selectDisabledBackground: '#CECECE',
  selectDisabledColor: '#9C9C9C',
  selectMultiBackground: '#e6e6e6',
  selectMultiBorder: '0',
  selectMenuRadius: '5px',
  selectMenuBorder: `1px solid #eee`,

  /**
   * Chart
   */
  chartColors: new Array(12).fill(primaryThemeColor).map((a, i) =>
    c(a)
      .rotate(i * 24)
      .hex()
  ),

  /**
   * Month Picker styles
   */

  monthPickerBackground: primaryThemeColor,
  monthPickerDateBackground: primaryThemeColor,
  monthPickerButtonBackground: primaryThemeColor,
  monthPickerChevron: '#fff',
  monthPickerFontColor: c(primaryThemeColor)
    .darken(0.3)
    .string(),
  monthPickerColor: '#fff',
  monthPickerButtonBorder: 'none',
  monthPickerButtonRadius: '0',
  monthPickerButtonMargin: '0.5em 0',
  monthPickerButtonTextPadding: '0.75em 1.05em',
  monthPickerButtonTextTransform: 'uppercase',
  monthPickerHoverBackground: '#688C8B',
  monthPickerActiveBackground: '#688C8B',
  monthPickerDisabledBackground: '#9A9A9A',
  monthPickerDisabledColor: '#6A6A6A',
  monthPickerButtonFontSize: '0.95em',
  monthPickerButtonMinWidth: '0',
  monthPickerButtonMinHeight: '0',
  monthPickerButtonFontWeight: '400',
  monthPickerLetterSpacing: '0.045em',
  monthPickerButtonTextAlign: 'center',
};

export default theme;
