import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import InputLabel from '../../atoms/InputLabel';
import Error from '../../atoms/Error';
import InputWrapper from '../../atoms/InputWrapper';
import { composeValidators } from '../../utils';
import Hint from '../utils/Hint';
import { Controlled as ReactCodeMirror } from 'react-codemirror2';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/theme/tomorrow-night-eighties.css';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import { JSHINT } from 'jshint';
window.JSHINT = JSHINT;

const CodeMirrorStyled = styled(ReactCodeMirror)`
  flex: 1 1 auto;
  margin-top: 0;
  height: 300px;
  z-index: 0;
  position: relative;
  .CodeMirror {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
  }
  &.error {
    border: 2px solid ${props => props.theme.fieldError};
  }
`;

const EditorField = ({
  name,
  validate,
  label,
  onChange,
  optional,
  required,
  validateOnInit,
}) => (
  <Field name={name} validate={composeValidators(validate)}>
    {({ input, meta }) => {
      return (
        <InputWrapper>
          {label && <InputLabel htmlFor={input.name}>{label}</InputLabel>}
          <CodeMirrorStyled
            className={meta.dirty && meta.error ? 'error' : ''}
            value={input.value}
            options={{
              mode: 'javascript',
              lineNumbers: true,
              gutters: ['CodeMirror-lint-markers'],
              lint: true,
              theme: 'tomorrow-night-eighties',
            }}
            onBeforeChange={(editor, data, value) => {
              if (onChange) onChange(value);
              input.onChange(value);
            }}
          />

          {meta.dirty && meta.error && (
            <Error id={`${input.name}Error`} role="alert" palette="danger">
              {meta.error}
            </Error>
          )}
          {!((meta.touched || validateOnInit) && meta.error) && required && (
            <Hint role="info">Required</Hint>
          )}
          {!((meta.touched || validateOnInit) && meta.error) && optional && (
            <Hint role="info">Optional</Hint>
          )}
        </InputWrapper>
      );
    }}
  </Field>
);

EditorField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

EditorField.defaultProps = {
  label: null,
  onChange: null,
  validate: () => {},
};

export default EditorField;
