import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Login from '@pages/LoginPage';
import Register from '@pages/RegisterPage';
import ForgotPassword from '@pages/ForgotPasswordPage';
import SelectOrganization from '@pages/SelectOrganization';
import { withRouteAuth } from '@contexts/FirebaseContext';
import ScrollToTop from '@atoms/ScrollToTop';
import Devices from '../Devices';
import Device from '../Device';
import DevicesCreate from '../DevicesCreate';
import Events from '../Events';

import * as routes from '../routes';

const authCondition = (authUser) => !!authUser;

const App = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route
          exact
          path={routes.DEVICES}
          component={withRouteAuth(
            authCondition,
            routes.LOGIN,
            routes.SELECT_ORGANIZATION
          )(Devices)}
        />
        <Route
          exact
          path={routes.SELECT_ORGANIZATION}
          component={withRouteAuth(
            authCondition,
            routes.LOGIN,
            routes.SELECT_ORGANIZATION
          )(SelectOrganization)}
        />
        <Route
          exact
          path={routes.CREATE}
          component={withRouteAuth(
            authCondition,
            routes.LOGIN,
            routes.SELECT_ORGANIZATION
          )(DevicesCreate)}
        />
        <Route
          exact
          path={routes.EVENTS}
          component={withRouteAuth(
            authCondition,
            routes.LOGIN,
            routes.SELECT_ORGANIZATION
          )(Events)}
        />
        <Route
          exact
          path={`${routes.DEVICES}/:id`}
          component={withRouteAuth(
            authCondition,
            routes.LOGIN,
            routes.SELECT_ORGANIZATION
          )(Device)}
        />
        <Route
          exact
          path={routes.LOGIN}
          component={() => <Login toRoute={routes.SELECT_ORGANIZATION} />}
        />
        <Route exact path={routes.REGISTER} component={() => <Register />} />
        <Route
          exact
          path={routes.FORGOT_PASSWORD}
          component={() => <ForgotPassword />}
        />
        <Redirect from="/" to="/devices" />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default App;
