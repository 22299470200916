import gql from 'graphql-tag';

import {deviceTestsFragment} from '../fragments/device-tests';

export const GET_DEVICE_TESTS = gql`
  query deviceProductionTests($deviceId: String!) {
    deviceProductionTests(deviceId: $deviceId) {
      ...deviceTests
  }
  }
  ${deviceTestsFragment}
`;