import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _get from 'lodash/get';

import PageTemplate from '../../templates/PageTemplate';
import LoaderLine from '../../atoms/LoaderLine';
import LoadMoreTable from '../../organisms/LoadMoreTable';
import SearchPane from '../../organisms/SearchPane';

const StyledLoaderLine = styled(LoaderLine)`
  position: sticky;
  top: 0;
  z-index: 1000;
`;

let _filterTimer;
const DashboardEventPage = ({
  gqlData,
  filter,
  onLoadMore,
  onAddClick,
  actions,
  loading,
  onItemClick,
  columns,
  children,
  searchComponents,
  initialSearch,
  hideBanner,
}) => {
  const edges = _get(gqlData, 'edges');
  const pageInfo = _get(gqlData, 'pageInfo');
  const f = _get(edges, '[0]', {});
  const col =
    columns ||
    Object.keys(f)
      .filter(p => p !== '__typename')
      .map(p => ({ key: p, name: p }));

  return (
    <PageTemplate
      actionsPane={
        <SearchPane
          onChange={value => {
            clearInterval(_filterTimer);
            _filterTimer = setTimeout(() => filter(value), 200);
          }}
          onAddClick={onAddClick}
          actions={actions}
          searchComponents={searchComponents}
          defaultValue={initialSearch}
        />
      }
      hideBanner={hideBanner}
    >
      {children}
      <StyledLoaderLine loading={loading} />

      {edges && (
        <LoadMoreTable
          onRowClick={onItemClick}
          columns={col}
          data={edges}
          onLoadMore={onLoadMore}
          hasMore={pageInfo && pageInfo.hasNext}
        />
      )}
    </PageTemplate>
  );
};

DashboardEventPage.propTypes = {
  gqlData: PropTypes.object,
  filter: PropTypes.func,
  onLoadMore: PropTypes.func,
  onAddClick: PropTypes.func,
  actions: PropTypes.array,
  onItemClick: PropTypes.func,
  user: PropTypes.object,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  initialSearch: PropTypes.string,
};

export default DashboardEventPage;
