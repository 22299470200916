import React from 'react';
import { Flex } from '@rebass/grid';
import styled from 'styled-components';
import Card from '@components/atoms/Card';
import Label from '@components/atoms/Label';
import Caption from '@components/atoms/Caption';

const StyledCaption = styled(Caption)`
  padding: 0.2em 0.8em 0.5em 0;
`;

const StyledLabel = styled(Label)`
  color: #000;
  display: block;
  text-transform: none;
  &:empty::after {
    content: '';
    display: block;
    width: ${(props) => (props.short ? '40%' : '60%')};
    height: 1.7em;
    border-radius: 2px;
    background: rgba(216, 216, 216, 0.5);
    margin: 0.2em 0;
  }
`;

const LabelHeading = styled.p`
  display: inline;
  font-weight: bold;
  color: ${(props) => props.theme.labelBoldColor};
`;

const ProductionStateText = styled.p`
  display: inline;
  color: ${(props) => (props.error ? 'red' : '#000')};
`;

const InfoCard = styled(Card)`
  border-left: ${(props) => props.theme.cardLeftBorder};
  padding: 2em;
`;

const StyledInfoCard = styled((props) => <InfoCard {...props} />)`
flex: 1 1 auto
width: 30%
`;
const NewDevices = ({ devices }) => {
  const title = 'Devices Are Being Assembled';

  return devices.length ? (
    <InfoCard title={title}>
      <StyledCaption>{title}</StyledCaption>
      <Flex flexWrap={['wrap', null]}>
        {devices.map((device, index) => (
          <StyledInfoCard key={`unknowndevice-${index}`}>
            <StyledLabel short>
              <LabelHeading>MAC: </LabelHeading>
              {device.mac}
            </StyledLabel>
            <StyledLabel short>
              <LabelHeading>Status: </LabelHeading>
              <ProductionStateText error={device.error}>
                {`${device.step}/${device.nbrOfSteps} ${device.status}`}
              </ProductionStateText>
            </StyledLabel>
          </StyledInfoCard>
        ))}
      </Flex>
    </InfoCard>
  ) : (
    <></>
  );
};

export default NewDevices;
