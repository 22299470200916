import gql from 'graphql-tag';

export const devicesInfoFragment = gql`
  fragment devicesInfo on Device {
    id
    name
    tag
    product
    lifecycleState
    settings {
      data
    }
    productionStatus {
      status
      nbrOfSteps
      step
      err
      mac
    }
    productionTests {
      state
    }
    connectionEvent {
      timestamp
      data {
        after
      }
    }
    lifecycleEvent {
      timestamp
      data {
        after
      }
    }
    socketServer
  }
`;

export const deviceInfoFragment = gql`
  fragment deviceInfo on Device {
    id
    name
    tag
    product
    lifecycleState
    socketServer
  }
`;

export const GET_DEVICES = gql`
  query devices(
    $filter: DeviceFilter
    $limit: Int
    $after: String
    $sort: JSON
  ) {
    devices(filter: $filter, limit: $limit, after: $after, sort: $sort) {
      edges {
        ...devicesInfo
      }
      pageInfo {
        start
        end
        hasNext
      }
    }
  }
  ${devicesInfoFragment}
`;

export const GET_DEVICE = gql`
  query device($id: String!) {
    device(id: $id) {
      ...deviceInfo
    }
  }
  ${deviceInfoFragment}
`;

export const GET_DEVICE_TESTS_STATUS = gql`
  query device($id: String!) {
    device(id: $id) {
      productionTests {
        state
      }
    }
  }
`;
