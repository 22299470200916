import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '@components/atoms/Card';
import Label from '@components/atoms/Label';
import ConnectionState from '@components/atoms/ConnectionState';
import useRealtimeDevice from '@components/hooks/useRealtimeDevice';
import { Flex, Box } from '@rebass/grid';
import PropTypes from 'prop-types';

const StyledInfoCard = styled(Card)`
  border-left: ${(props) => props.theme.cardLeftBorder};
  padding: 2em;
  flex: 1 1 auto;
`;

const StyledLabel = styled(Label)`
  color: #000;
  display: block;
  text-transform: none;
  &:empty::after {
    content: '';
    display: block;
    width: ${(props) => (props.short ? '40%' : '60%')};
    height: 1.7em;
    border-radius: 2px;
    background: rgba(216, 216, 216, 0.5);
    margin: 0.2em 0;
  }
`;

const LabelHeading = styled.p`
  display: inline;
  font-weight: bold;
  color: ${(props) => props.theme.labelBoldColor};
`;

const Configurator = ({ device, onNotification }) => {
  const { connected, device: connectedDevice } = useRealtimeDevice(device);
  const [connectedFlashers, setConnectedFlashers] = useState([]);

  const setFlashers = (flashers) => {
    setConnectedFlashers(
      Object.values(flashers).filter((flasher) => !!flasher.connected)
    );
  };

  useEffect(() => {
    async function getFlashers() {
      const res = await connectedDevice.sendCustom('ble-flashers');
      setFlashers(res);
    }

    function subscribeFlashers() {
      connectedDevice.on('ble-flashers', (res) => {
        setFlashers(res);
      });
      connectedDevice.subscribe('ble-flashers');
    }

    if (connectedDevice && connected) {
      connectedDevice.on('production-status', onNotification);
      connectedDevice.subscribe('production-status');
      getFlashers();
      subscribeFlashers();
    }
  }, [connected, connectedDevice, onNotification]);

  return (
    <StyledInfoCard>
      <Flex flexWrap={['wrap', null]}>
        <Box width={[1, 1, 1]}>
          <StyledLabel short>
            <LabelHeading>ID: </LabelHeading>
            {device.id}
          </StyledLabel>
          <StyledLabel short>
            <LabelHeading>Type: </LabelHeading>
            {device.product}
          </StyledLabel>
          <StyledLabel short>
            <LabelHeading>Name: </LabelHeading>
            {device.name}
          </StyledLabel>
          <StyledLabel short>
            <LabelHeading>Connected flashers: </LabelHeading>
            {connectedFlashers.length
              ? connectedFlashers
                  .map((flasher) => `${flasher.type} (${flasher.id})`)
                  .join(', ')
              : '-'}
          </StyledLabel>
          <StyledLabel short>
            <LabelHeading>Connection Status: </LabelHeading>
            <ConnectionState key={device.id} device={device} />
          </StyledLabel>
        </Box>
      </Flex>
    </StyledInfoCard>
  );
};

Configurator.propTypes = {
  device: PropTypes.object,
  onNotification: PropTypes.func,
};

export default Configurator;
