import React, { Component } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { Box } from '@rebass/grid';
import { GET_EVENTS } from '../api/queries/events';
import Icon from '@components/atoms/Icon';

import { SharedToasterConsumer } from '@components/contexts/ToasterContext';
import { withLivionConnect } from '@components/contexts/LivionConnectContext';
import DashboardEventPage from '@components/pages/DashboardEventPage';
import Modal from '@components/molecules/Modal';
import DisplayJsonData from '@components/organisms/DisplayJsonData';

import {
  decodeQueryString,
  setQueryString,
  encodeQueryString,
} from '@components/utils';

const BackBox = styled(Box)`
  cursor: pointer;
`;

const BackIcon = styled(Icon)`
  fill: ${(props) => props.theme.headingColor};
`;

const pageSize = 100;

export class Events extends Component {
  static isPrivate = true;

  state = {
    event: null,
  };

  componentDidMount() {
    const filters = this.getFilters();
    let variables = {
      filter: {},
      limit: pageSize,
      after: '0',
    };
    if (filters) {
      this.setFilters(filters);
    } else {
      this.setFilters(variables);
    }
  }

  getFilters = () => {
    const params = new URLSearchParams(this.props.location.search);
    return {
      ...decodeQueryString(decodeURIComponent(params.toString())),
      sort: { timestamp: -1 },
    };
  };
  setFilters = (filters) => {
    setQueryString(this.props.history, encodeQueryString(filters));
  };

  onFilterHandle = (fullText) => {
    let variables = this.getFilters();
    if (!variables.filter) variables.filter = {};
    variables.filter.fullText = fullText;
    setQueryString(this.props.history, encodeQueryString(variables));
  };

  onLoadMore = async (data, fetchMore) => {
    let variables = this.getFilters();
    variables.after = data.events.edges.length.toString();
    await fetchMore({
      variables: variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          events: {
            ...prev.events,
            pageInfo: {
              ...prev.events.pageInfo,
              ...fetchMoreResult.events.pageInfo,
            },
            edges: [...prev.events.edges, ...fetchMoreResult.events.edges],
          },
        });
      },
    });
    variables.limit = pageSize;
    variables.after = '0';
    setQueryString(this.props.history, encodeQueryString(variables));
  };

  render() {
    const { event } = this.state;
    return (
      <SharedToasterConsumer>
        {({ addToast }) => (
          <React.Fragment>
            <Query
              query={GET_EVENTS}
              variables={this.getFilters()}
              notifyOnNetworkStatusChange
              fetchPolicy="cache-and-network"
            >
              {({
                loading,
                error,
                data,
                fetchMore,
                refetch,
                networkStatus,
                variables,
                ...props
              }) => {
                let events = {};
                if (data.events) {
                  events = {
                    ...data.events,
                    edges:
                      data.events.edges &&
                      data.events.edges.map((e) => {
                        const timestamp = dayjs(e.timestamp).format(
                          'DD.MM.YYYY H:mm:ss'
                        );
                        return {
                          ...e,
                          timestamp,
                        };
                      }),
                  };
                }
                if (error) {
                  addToast({
                    type: 'error',
                    title: 'Error',
                    message: error.message,
                  });
                }
                return (
                  <React.Fragment>
                    <DashboardEventPage
                      gqlData={events}
                      {...props}
                      searchComponents={() => (
                        <BackBox pr={'1em'} onClick={this.props.history.goBack}>
                          <BackIcon icon={'chevron-left'} size={'2em'} />
                        </BackBox>
                      )}
                      initialSearch={
                        variables.filter && variables.filter.fullText
                      }
                      columns={[
                        {
                          name: 'Timestamp',
                          render: (data) => (
                            <div style={{ maxWidth: '200px', fontSize: 14 }}>
                              {data.timestamp}
                            </div>
                          ),
                        },
                        {
                          name: 'Data',
                          render: (data) => (
                            <div
                              style={
                                data.action === 'production-status'
                                  ? {
                                      maxWidth: '400px',
                                    }
                                  : {
                                      maxWidth: '300px',
                                      wordWrap: 'break-word',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }
                              }
                            >
                              {data.action === 'production-status' ? (
                                <div style={{ fontSize: 14 }}>
                                  <div>
                                    <span style={{ fontWeight: 600 }}>
                                      Mac:
                                    </span>{' '}
                                    {data.data.after.mac}
                                  </div>
                                  <div>
                                    <span style={{ fontWeight: 600 }}>
                                      Nbr of Steps:
                                    </span>{' '}
                                    {data.data.after.nbrOfSteps}
                                  </div>
                                  <div>
                                    <span style={{ fontWeight: 600 }}>
                                      Status:
                                    </span>{' '}
                                    {data.data.after.status}
                                  </div>
                                  <div>
                                    <span style={{ fontWeight: 600 }}>
                                      Step:
                                    </span>{' '}
                                    {data.data.after.step}
                                  </div>
                                </div>
                              ) : (
                                JSON.stringify(data.data)
                              )}
                            </div>
                          ),
                        },
                      ]}
                      loading={loading}
                      error={error}
                      filter={({ search }) => {
                        if (search) this.onFilterHandle(search);
                      }}
                      onLoadMore={() => this.onLoadMore(data, fetchMore)}
                    />
                    {event && (
                      <React.Fragment>
                        <Modal
                          header={'Event data'}
                          onClose={() => this.setState({ event: null })}
                        >
                          <DisplayJsonData data={event} depth={6} />
                        </Modal>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              }}
            </Query>
          </React.Fragment>
        )}
      </SharedToasterConsumer>
    );
  }
}

export default withLivionConnect(Events);
