import gql from 'graphql-tag';
export const eventInfoFragment = gql`
  fragment eventInfo on Event {
    id
    timestamp
    source
    sourceId
    tag
    action
    originator {
      id
      email
      type
      name
    }
  }
`;
export const eventDataFragment = gql`
  fragment eventData on Event {
    data {
      text
      sourceTime
      value
      before
      after
    }
  }
`;
