import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Label from '@components/atoms/Label';
import useRealtimeDevice from '@components/hooks/useRealtimeDevice';
import { Flex, Box } from '@rebass/grid';
import Button from '@components/atoms/Button';
import PropTypes from 'prop-types';
import ConnectionState from '@components/atoms/ConnectionState';
import get from 'lodash.get';
import Card from '@components/atoms/Card';
import CheckBox from '@components/atoms/CheckBox';

const StyledInfoCard = styled(Card)`
  border-left: ${(props) => props.theme.cardLeftBorder};
  padding: 2em;
  flex: 1 1 auto;
`;

const StyledLabel = styled(Label)`
  color: #000;
  display: block;
  text-transform: none;
  &:empty::after {
    content: '';
    display: block;
    width: ${(props) => (props.short ? '40%' : '60%')};
    height: 1.7em;
    border-radius: 2px;
    background: rgba(216, 216, 216, 0.5);
    margin: 0.2em 0;
  }
`;

const CheckBoxLabel = styled(Label)`
  color: #000;
  display: inline;
  text-transform: none;
`;

const LabelHeading = styled.p`
  display: inline;
  font-weight: bold;
  color: ${(props) => props.theme.labelBoldColor};
`;

const StatusLabel = styled.span`
  ${(props) => (props.error ? 'color: red' : '')}
`;

const StyledText = styled.p`
  word-wrap: break-word;
  display: inline;
`;

const Flasher = ({ device }) => {
  const { connected, device: connectedDevice } = useRealtimeDevice(device);

  const [status, setStatus] = useState({
    type: 'info',
    message: 'not available',
  });

  const [flashResult, setFlashResult] = useState();

  const [recreateKey, setRecreateKey] = useState(false);

  const isFlashButtonDisabled = () =>
    (status.message !== 'IDLE' && status.type !== 'error') || !connected;

  const onFlash = async () => {
    try {
      setStatus({ message: 'sending command...', type: 'info' });
      setFlashResult(null);
      if (recreateKey) {
        const res = await connectedDevice.sendCustom('flash', {}, 120000);
        setFlashResult(res);
      } else {
        await connectedDevice.sendCustom('flashOnly', {});
      }
    } catch (err) {
      setStatus({ message: err.message, type: 'error' });
    }
  };

  useEffect(() => {
    async function getStatus() {
      const resStatus = await connectedDevice.sendCustom('flash-status');
      setStatus(resStatus);
    }
    if (connected && connectedDevice) {
      getStatus();
      connectedDevice.on('flash-status', setStatus);
      connectedDevice.subscribe('flash-status');
    }
  }, [connected, connectedDevice]);

  return (
    <StyledInfoCard>
      <Flex flexWrap={['wrap', null]}>
        <Box width={[1, 1, 1]}>
          <StyledLabel short>
            <LabelHeading>ID: </LabelHeading>
            {device.id}
          </StyledLabel>
          <StyledLabel short>
            <LabelHeading>Type: </LabelHeading>
            {get(device, 'settings.data.flasher.type')}
          </StyledLabel>
          <StyledLabel short>
            <LabelHeading>Status: </LabelHeading>
            <StatusLabel error={status.type === 'error'}>
              {status.message}
            </StatusLabel>
          </StyledLabel>
          <StyledLabel short>
            <LabelHeading>Connection Status: </LabelHeading>
            <ConnectionState key={device.id} device={device} />
          </StyledLabel>
        </Box>
        <Box pt={'1em'} width={[1, 1, 1]}>
          <Button
            style={{ marginRight: '1em' }}
            small
            disabled={isFlashButtonDisabled()}
            onClick={onFlash}
          >
            Flash
          </Button>
          <CheckBox
            checked={recreateKey}
            onClick={() => setRecreateKey(!recreateKey)}
          />
          <CheckBoxLabel>Recreate encyption key</CheckBoxLabel>
        </Box>
        {flashResult && (
          <Box mt={'2em'} width={[1, 1, 1]}>
            <StyledLabel short>
              <LabelHeading>Hardware id: </LabelHeading>
              <StyledText>{flashResult.hwId}</StyledText>
            </StyledLabel>
            <StyledLabel short>
              <LabelHeading>Key: </LabelHeading>
              <StyledText>{flashResult.key}</StyledText>
            </StyledLabel>
          </Box>
        )}
      </Flex>
    </StyledInfoCard>
  );
};

Flasher.propTypes = {
  device: PropTypes.object,
};

export default Flasher;
