import gql from 'graphql-tag';

export const CHANGE_STATE = gql`
  mutation changeLifecycleState(
    $id: String!
    $newState: String!
    $data: JSON!
  ) {
    changeLifecycleState(id: $id, newState: $newState, data: $data)
  }
`;

export const DELETE_LIFECYCLE_EVENT = gql`
  mutation deleteLastLifecycleState($id: String!) {
    deleteLastLifecycleState(id: $id) {
      id
    }
  }
`;

export const CREATE_DEVICES = gql`
  mutation createDevicesFromProduction(
    $input: CreateProductionDeviceInput!
    $quantity: Int!
    $generateHwId: Boolean
  ) {
    createDevicesFromProduction(
      input: $input
      quantity: $quantity
      generateHwId: $generateHwId
    )
  }
`;
