import React from 'react';
import { Box } from '@rebass/grid';
import productsList from '../products';
import Select from '@components/atoms/Select';

const SelectProduct = ({ onChange, product }) => (
  <Box width={[1, 1, 1]}>
    <Select
      value={product && product.name}
      select={{
        valueKey: 'name',
        labelKey: 'name',
        options: productsList,
        placeholder: 'Select product',
      }}
      onChange={(value, option) => {
        window.localStorage.setItem('product', JSON.stringify(option));
        onChange && onChange(option);
      }}
    />
  </Box>
);

export default SelectProduct;
