const products = [
  { name: 'roomrobot', configurator: 'production-configurator' },
  { name: 'key30', configurator: 'production-configurator' },
  { name: 'key20', configurator: 'production-configurator' },
  { name: 'keyX', configurator: 'production-configurator' },
  { name: 'keypad', configurator: 'production-configurator' },
  { name: 'ble-flasher', configurator: 'production-configurator' },
];

export default products;
