import gql from 'graphql-tag';

export const deviceTestsFragment = gql`
  fragment deviceTests on DeviceTests {
    timestamp
    state
    start
    end
    deviceId
    tests {
      name
      state
      duration
      type
      sequentially
      deviceCommands {
        name
        command
      }
      details {
        start
        end
        rate
        data
      }
    }
  }
`;
