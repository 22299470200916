import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { withLivionConnect } from '@components/contexts/LivionConnectContext';
import { GET_DEVICE_TESTS_STATUS } from '../api/queries/devices';
import { CHANGE_STATE } from '../api/mutations/devices';
import DashboardDefaultPage from '@components/pages/DashboardDefaultPage';
import { Flex } from '@rebass/grid';
import get from 'lodash/get';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import Label from '@components/atoms/Label';
import Icon from '@components/atoms/Icon';
import styled from 'styled-components';
import DeviceTestsCard from '@components/cards/DeviceTestsCard';
import { SharedToasterConsumer } from '@components/contexts/ToasterContext';
import Button from '@components/atoms/Button';
import useRealtimeDevice from '@components/hooks/useRealtimeDevice';

const StyledFlex = styled(Flex)`
  padding: 0.5em 4.375em;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    padding: 0.4em;
  }
`;

const StatusLabel = styled(Label)`
  font-size: ${(props) => props.theme.statusLabelSize};
  &.connected {
    color: ${(props) => props.theme.statusLabelConnectedColor};
  }
  &.disconnected {
    color: ${(props) => props.theme.statusLabelDisconnectedColor};
  }
`;

const StyledIcon = styled(Icon)`
  fill: ${(props) => props.theme.plugColor};
  padding: 0.1em 0.5em;
`;

const Device = (props) => {
  const {
    match: { params },
    history,
    state: { user, navigationItems },
    actions: { logout },
  } = props;

  const client = useApolloClient();

  const { error, loading, connected, deviceData } = useRealtimeDevice(
    { id: params.id },
    true
  );

  useEffect(() => {
    if (!connected && !loading) setDeviceStatus('disconnected');
    if (connected) setDeviceStatus('connected');
  }, [connected, loading]);

  let [deviceStatus, setDeviceStatus] = useState('connecting...');

  let [lifecycleState, setLifecycleState] = useState();
  const [changeState] = useMutation(CHANGE_STATE);

  const changeLifecycleState = (addToast) => {
    const state = 'accepted';
    client
      .query({
        fetchPolicy: 'no-cache',
        query: GET_DEVICE_TESTS_STATUS,
        variables: {
          id: params.id,
        },
      })
      .then(({ data }) => {
        const { device } = data;

        if (
          !device.productionTests ||
          device.productionTests.state !== 'completed'
        ) {
          throw new Error(
            'No possible to approve device until tests are not sucsessfully completed'
          );
        } else {
          return changeState({
            variables: {
              id: params.id,
              newState: state,
              data: {},
            },
          }).then(() => {
            addToast({
              type: 'success',
              title: `Success`,
              message: `Device lifecycle state updated successfully to "${state}"`,
            });
            setLifecycleState(state);
            history.push('/devices');
          });
        }
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: `Error`,
          message: e.message || e,
        });
      });
  };

  useEffect(() => {
    setLifecycleState(get(deviceData, 'lifecycleState'));
  }, [setLifecycleState, deviceData]);

  if (!loading && !deviceData) {
    history.replace('/error');
    return <div />;
  }

  return (
    <SharedToasterConsumer>
      {({ addToast }) => (
        <React.Fragment>
          {!loading && ( // Render form only when data is ready -> otherwise messes up initial values
            <DashboardDefaultPage
              navigationItems={navigationItems}
              gqlData={deviceData}
              user={user}
              {...props}
              loading={loading}
              error={error}
              title={get(deviceData, 'name')}
              subtitle={get(deviceData, 'id')}
              status={
                <Flex
                  style={{
                    border: '1px solid gray',
                    paddingRight: '1em',
                    borderRadius: '1em',
                    marginLeft: '1em',
                  }}
                  alignItems="center"
                >
                  <StyledIcon icon={'plug'} size={'1em'} />
                  <StatusLabel className={deviceStatus}>
                    {deviceStatus}
                  </StatusLabel>
                </Flex>
              }
              onProfileClick={(v) => history.push(`/users/${v.uid}/edit`)}
              onLogoutClick={logout}
              onBack={history.goBack}
              customActions={
                lifecycleState === 'assembled' && (
                  <Button onClick={() => changeLifecycleState(addToast)}>
                    Approve
                  </Button>
                )
              }
            >
              <StyledFlex flexWrap="wrap">
                <DeviceTestsCard
                  zoomed={true}
                  id={params.id}
                  toaster={{ addToast }}
                  device={deviceData}
                />
              </StyledFlex>
            </DashboardDefaultPage>
          )}
        </React.Fragment>
      )}
    </SharedToasterConsumer>
  );
};

export default withRouter(withLivionConnect(Device));
