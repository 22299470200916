import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@rebass/grid';
import Icon from '../../atoms/Icon';
import styled from 'styled-components';

const ToggleIcon = styled(props => <Icon {...props} />)`
  color: ${props => props.theme.toggleColor};
  margin: auto;
  transition: 0.1s ease-out;
  transform: ${props => (props.open ? 'rotateZ(45deg)' : 'rotateZ(0)')};
  transform-origin: 10px 8px;
`;

class Hide extends React.Component {
  state = {
    visible: false,
  };

  toggle = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const {
      props: { children },
      state: { visible },
      toggle,
    } = this;
    return (
      <React.Fragment>
        <Box width={1} p={1}>
          <ToggleIcon
            size={'1.5em'}
            icon={'chevron-right'}
            open={visible}
            onClick={toggle}
          />
        </Box>
        {visible && children}
      </React.Fragment>
    );
  }
}

const parseArray = (data, depth, displayEmpty) => {
  if (depth <= 0) {
    return JSON.stringify(data);
  }
  return (
    // Immutable arrays can't be sorted, cast it to a new array, and sort
    [...data]
      .sort(d => d !== null && typeof d === 'object')
      .map(
        (d, i) =>
          ((d && d !== '') || displayEmpty) && (
            <Flex
              key={i}
              m={2}
              css={{
                border: '2px solid #d8d8d8',
                borderRadius: '5px',
              }}
            >
              <Box
                width={1}
                p={1}
                css={{
                  color: '#959595',
                  wordWrap: 'break-word',
                }}
              >
                {parseData(d, depth)}
              </Box>
            </Flex>
          )
      )
  );
};

const parseObject = (data, depth, displayEmpty) => {
  if (depth <= 0) {
    return JSON.stringify(data);
  }
  return Object.entries(data)
    .sort(([, d]) => d !== null && typeof d === 'object')
    .map(([k, d], i) => {
      const childIsObject = d && typeof d === 'object';
      return (
        ((d && d !== '') || displayEmpty) && (
          <Flex
            key={i}
            m={2}
            flexDirection={childIsObject ? 'column' : 'row'}
            css={{
              border: '2px solid #d8d8d8',
              borderRadius: '5px',
            }}
          >
            <Box
              width={(!childIsObject && 1 / 6) || 1}
              p={1}
              css={{ color: '#00C1BA' }}
            >
              {k}
            </Box>
            {(childIsObject && (
              <Hide>
                <Box
                  width={(!childIsObject && 5 / 6) || 1}
                  p={1}
                  css={{ color: '#959595' }}
                >
                  {parseData(d, depth)}
                </Box>
              </Hide>
            )) || (
              <Box
                width={(!childIsObject && 5 / 6) || 1}
                p={1}
                css={{
                  color: '#959595',
                  wordWrap: 'break-word',
                }}
              >
                {parseData(d, depth)}
              </Box>
            )}
          </Flex>
        )
      );
    });
};

const parseData = (data, depth = 2, displayEmpty = false) => {
  if (!data) {
    return 'No data';
  } else if (Array.isArray(data)) {
    return parseArray(data, depth - 1, displayEmpty);
  } else if (typeof data === 'object') {
    return parseObject(data, depth - 1, displayEmpty);
  } else {
    return data;
  }
};

const DisplayJsonData = ({ data, depth, displayEmpty }) =>
  parseData(data, depth, displayEmpty);

DisplayJsonData.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  depth: PropTypes.number,
  displayEmpty: PropTypes.bool,
};

export default DisplayJsonData;
