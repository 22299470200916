import React, { useState, useEffect, useCallback } from 'react';

import useEventCallback from '@components/hooks/useEventCallback';
import DashboardEventPage from '@components/pages/DashboardEventPage';
import { GET_DEVICES } from '../api/queries/devices';
import { DELETE_LIFECYCLE_EVENT } from '../api/mutations/devices';
import Caption from '@components/atoms/Caption';
import { Flex, Box } from '@rebass/grid';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import Label from '@components/atoms/Label';
import Button from '@components/atoms/Button';
import get from 'lodash/get';
import Icon from '@components/atoms/Icon';
import Configurators from '../Configurators';
import Flashers from '../Flashers';
import { SharedToasterConsumer } from '@components/contexts/ToasterContext';
import ConnectionState from '@components/atoms/ConnectionState';
import SelectProduct from './SelectProduct';
import NewDevices from './NewDevices';
import Link from '@components/atoms/Link';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const StyledIcon = styled(Icon)`
  cursor: pointer;
  fill: ${(props) => props.theme.tertiaryThemeColor};
`;

const StyledCaption = styled(Caption)`
  padding: 1.5em 0.8em 0.5em 1.3em;
`;

const ResponsiveLabel = styled(Label)`
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    font-size: 0.9em;
  }
`;

const DeviceLabel = styled(ResponsiveLabel)`
  text-transform: none;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
  max-width: 12em;
  display: block;
  overflow: hidden;
`;

const TestsStateLabel = styled(ResponsiveLabel)`
  text-transform: none;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
  max-width: 12em;
  display: block;
  overflow: hidden;
`;

const StatusLabel = styled(ResponsiveLabel)`
  text-transform: none;
  line-height: 1em;
  color: ${(props) => (props.error ? 'red' : props.theme.secondaryThemeColor)};
  display: block;
  width: 21em;
  overflow: hidden;
`;

const MessageLabel = styled(Label)`
  text-transform: none;
  font-size: 0.8em;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
  display: block;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    font-size: 0.6em;
  }
`;

const LifecycleStateLabel = styled(Label)`
  cursor: pointer;
  text-transform: none;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
`;

const LifecycleMessageLabel = styled(MessageLabel)`
  font-size: 0.7em;
  line-height: 0.75em;
`;

const StyledWarningBox = styled(Flex)`
  top: 10%;
  color: red;
  justify-content: center;
`;

const StyledWarningIcon = styled(Icon)`
  fill: red;
`;

const Devices = ({ auth, history }) => {
  let [tag] = useState(auth.getSelectedTag());

  let [product, setProduct] = useState(
    JSON.parse(window.localStorage.getItem('product'))
  );

  const variables = {
    filter: {
      tagExact: tag,
      product: product && product.name,
      lifecycleState: 'assembled',
    },
    after: '0',
    limit: 0,
    sort: {
      'lifecycleEvent.timestamp': -1,
    },
  };

  const { data, loading, error, refetch } = useQuery(GET_DEVICES, {
    variables,
    skip: !product,
  });

  const [deleteLifecycleEvent] = useMutation(DELETE_LIFECYCLE_EVENT);

  let [devices, setDevices] = useState([]);
  let [newDevices, setNewDevices] = useState([]);
  let [searchResult, setSearchResult] = useState();

  useEffect(() => {
    if (data && Object.keys(data).length && refetch) refetch();
  }, [data, refetch]);

  const onNotification = useEventCallback(
    (notification) => {
      notification.deviceId && notification.deviceId !== 'undefined'
        ? updateDeviceStatus(notification) && removeNewDevice(notification)
        : pushOrEditNewDevice(notification);
    },
    [devices]
  );

  const onResetState = useCallback(
    async (id) => {
      await deleteLifecycleEvent({
        variables: {
          id,
        },
      });
      refetch?.();
    },
    [refetch, deleteLifecycleEvent]
  );

  const pushOrEditNewDevice = (notification) => {
    let devices = [...newDevices];
    const index = devices.findIndex(
      (device) => device.mac === notification.mac
    );
    if (index > -1) {
      devices[index].status = notification.status;
      devices[index].nbrOfSteps = notification.nbrOfSteps;
      devices[index].step = notification.step;
      devices[index].error = notification.error;
      setNewDevices(devices);
    } else {
      devices.push(notification);
      setNewDevices(devices);
    }
  };

  const removeNewDevice = (notification) => {
    const edges = [...newDevices];
    const index = edges.findIndex((device) => device.mac === notification.mac);

    if (index > -1) {
      edges.splice(index, 1);
      setNewDevices(edges);
    }
  };

  const updateDeviceStatus = (notification) => {
    let edges = [...devices];
    let index = edges.findIndex(
      (device) => device.id === notification.deviceId
    );

    if (index > -1) {
      edges[index].productionStatus = notification;
      setDevices(edges);
    } else {
      refetch();
    }
    return true;
  };

  useEffect(() => {
    data && data.devices && setDevices(data.devices.edges);
  }, [data]);

  const filter = ({ search } = {}) => {
    if (!search) return setSearchResult(null);
    if (devices && search) {
      const edges = devices.filter((edge) => edge.id.includes(search));
      setSearchResult(edges);
    }
  };

  return (
    <SharedToasterConsumer>
      {({ addToast }) => {
        if (error)
          addToast({
            type: 'error',
            title: 'Error',
            message: error.message || error,
          });
        return (
          <DashboardEventPage
            gqlData={{ edges: searchResult || devices }}
            loading={loading}
            filter={filter}
            actions={[
              {
                order: 1,
                title: 'Create',
                icon: <Icon icon="plus" size="2em" />,
                onClick: () => history.push(`/create`),
              },
            ]}
            searchComponents={() => (
              <Flex width={[1, 1 / 3]}>
                <SelectProduct
                  onChange={(product) => setProduct(product)}
                  product={product}
                />
              </Flex>
            )}
            columns={
              product && [
                {
                  name: 'Device',
                  sortBy: 'name',
                  render: (data) => {
                    const tagParts = data.tag.split('/');
                    let label = data.name;
                    if (tagParts[3]) label = `${tagParts[3]} / ${label}`;
                    if (tagParts[2]) label = `${tagParts[2]} / ${label}`;

                    return (
                      <DeviceLabel>
                        {`${label}`}
                        <MessageLabel>{data.id}</MessageLabel>
                      </DeviceLabel>
                    );
                  },
                },
                {
                  name: 'App',
                  render: (data) => (
                    <ConnectionState
                      key={data.id}
                      device={data}
                      history={history}
                    />
                  ),
                },
                {
                  hide: ['xs', 'sm', 'md'],
                  name: 'Lifecycle',
                  render: (data) => {
                    const state = get(data, 'lifecycleEvent.data.after.state');
                    const ts = get(data, 'lifecycleEvent.timestamp');

                    return (
                      state && (
                        <LifecycleStateLabel>
                          {state}
                          <LifecycleMessageLabel>
                            {ts ? dayjs(ts).format('DD.MM.YY HH:mm') : ''}
                          </LifecycleMessageLabel>
                        </LifecycleStateLabel>
                      )
                    );
                  },
                },
                {
                  name: 'Tests State',
                  render: ({ productionTests }) => {
                    const state = get(productionTests, 'state');
                    return <TestsStateLabel>{state}</TestsStateLabel>;
                  },
                },
                {
                  name: 'Status',
                  render: ({ productionStatus, id }) => {
                    const { status = '', nbrOfSteps = '', step = '', err } =
                      productionStatus || {};
                    return (
                      <StatusLabel error={err}>
                        <Flex alignItems="center">
                          <Box width={'85%'}>
                            {(status && `${step}/${nbrOfSteps} ${status}`) ||
                              ''}
                          </Box>
                        </Flex>
                      </StatusLabel>
                    );
                  },
                },
                {
                  name: 'Actions',
                  render: ({ id }) => {
                    return (
                      <Flex alignItems="center">
                        <Box mr={2}>
                          <Button
                            small
                            onClick={() =>
                              history.push(
                                `/events?limit=100&after=0&filter.sourceId=${id}&filter.action=production-status&sort={timestamp:-1}`
                              )
                            }
                          >
                            Events
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            small
                            onClick={() => {
                              onResetState(id);
                            }}
                          >
                            Reset State
                          </Button>
                        </Box>
                      </Flex>
                    );
                  },
                },
                {
                  name: '',
                  render: ({ id }) => (
                    <Link to={`/devices/${id}`}>
                      <StyledIcon size={'2em'} icon={'chevron-right'} />
                    </Link>
                  ),
                },
              ]
            }
          >
            {product && (
              <>
                <Configurators
                  configurator={product.configurator}
                  onNotification={onNotification}
                  tag={tag}
                  toaster={{ addToast }}
                />
                <Flashers tag={tag} toaster={{ addToast }} />
                <NewDevices devices={newDevices} />
                <StyledCaption>Assembled Devices</StyledCaption>
              </>
            )}
            {!product && (
              <StyledWarningBox
                alignItems="center"
                flexDirection="column"
                mt={'5%'}
              >
                <StyledWarningIcon icon={'warning'} size={'2.5em'} />
                PLEASE, SELECT PRODUCT FIRST
              </StyledWarningBox>
            )}
          </DashboardEventPage>
        );
      }}
    </SharedToasterConsumer>
  );
};

export default Devices;
