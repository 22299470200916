import gql from 'graphql-tag';

import {deviceTestsFragment} from '../fragments/device-tests';

export const START_DEVICE_TESTS = gql`
        mutation startDeviceTests($id: String!) {
        startDeviceTests(id: $id) {
          ...deviceTests
        }
      }
      ${deviceTestsFragment}
`;

export const CHANGE_TEST_STATE = gql`
    mutation changeTestState($deviceId: String! $input: [DeviceTestInput!]) {
        changeTestState(deviceId: $deviceId, input: $input) {
            ...deviceTests
        }
    }
    ${deviceTestsFragment}
`;